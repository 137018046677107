<template>
	<div>
		<el-row class="tac">
			<el-container style="height: 100vh">
				<el-aside
					:width="menuWidth"
					style="background-color: #545c64; overflow-x: hidden; height: 100vh"
				>
					<el-menu
						:collapse="isCollapse"
						unique-opened
						class="el-menu-vertical-demo"
						router
						background-color="#545c64"
						text-color="#fff"
						active-text-color="#ffd04b"
						:default-active="'/home-admin/home-page'"
					>
						<el-menu-item index="/home-admin/home-page" title="首页">
							<i class="el-icon-s-home" style="color: #fff"></i>
							<span>首页</span>
						</el-menu-item>
						<el-submenu index="2" v-show="authorization('/home-admin/routineManage')">
							<template slot="title">
								<i class="el-icon-s-tools" style="color: #fff; padding-bottom: 3px"></i>
								<span>常规管理</span>
							</template>
							<el-menu-item
								v-show="authorization('/home-admin/routineManage/user-list')"
								index="/home-admin/routineManage/user-list"
								>人员管理</el-menu-item
							>
							<el-menu-item
								v-show="authorization('/home-admin/routineManage/role-list')"
								index="/home-admin/routineManage/role-list"
								>角色管理</el-menu-item
							>

							<el-menu-item
								v-show="authorization('/home-admin/routineManage/region-manage')"
								index="/home-admin/routineManage/region-manage"
								>区域管理</el-menu-item
							>
							<el-menu-item
								v-show="authorization('/home-admin/routineManage/company-manage')"
								index="/home-admin/routineManage/company-manage"
								>公司管理</el-menu-item
							>
							<el-menu-item
								v-show="authorization('/home-admin/routineManage/group-manage')"
								index="/home-admin/routineManage/group-manage"
								>群组管理</el-menu-item
							>
							<el-menu-item
								v-show="authorization('/home-admin/routineManage/userEntry-manage')"
								index="/home-admin/routineManage/userEntry-manage"
								>入职管理</el-menu-item
							>
							<el-menu-item
								v-show="authorization('/home-admin/routineManage/userEntry-manage')"
								index="/home-admin/routineManage/dict"
								>字典管理</el-menu-item
							>
						</el-submenu>
						<el-menu-item
							v-show="authorization('/home-admin/order/order-manage')"
							index="/home-admin/order/order-manage"
							title="订单管理"
						>
							<i class="el-icon-s-order" style="color: #fff"></i>
							<span>订单管理</span>
						</el-menu-item>
						<el-menu-item
							v-show="authorization('/home-admin/order/order-manage')"
							index="/home-admin/order/repair-order"
							title="维修单管理"
						>
							<i class="el-icon-s-order" style="color: #fff"></i>
							<span>维修单管理</span>
						</el-menu-item>

            <el-menu-item
                v-show="authorization('/home-admin/order/order-manage')"
                index="/home-admin/order/complete-rate-manage"
                title="报表管理"
            >
              <i class="el-icon-data-analysis" style="color: #fff"></i>
              <span>报表管理</span>
            </el-menu-item>

						<el-menu-item
							v-show="authorization('/home-admin/order/settle-manage')"
							index="/home-admin/order/settle-manage"
							title="结算管理"
						>
							<i class="el-icon-coin" style="color: #fff"></i>
							<span>结算记录</span>
						</el-menu-item>
						<el-submenu index="4" v-show="authorization('/home-admin/device')">
							<template slot="title">
								<i class="el-icon-s-platform" style="color: #fff"></i>
								<span>设备管理</span>
							</template>
							<el-menu-item
								v-show="authorization('/home-admin/device/device-class')"
								index="/home-admin/device/device-class"
								>设备类别</el-menu-item
							>
							<el-menu-item
								v-show="authorization('/home-admin/device/device-image-setting')"
								index="/home-admin/device/device-image-setting"
								>图片模板管理</el-menu-item
							>
							<el-menu-item
								v-show="authorization('/home-admin/device/device-manage')"
								index="/home-admin/device/device-manage"
								>设备信息</el-menu-item
							>
							<el-menu-item
								v-show="authorization('/home-admin/device/device-borrow')"
								index="/home-admin/device/device-borrow"
								>转借记录</el-menu-item
							>
						</el-submenu>
						<el-submenu index="5" v-show="authorization('/home-admin/routineManage')">
							<template slot="title">
								<i class="el-icon-coin" style="color: #fff"></i>
								<span>基础数据管理</span>
							</template>
							<el-menu-item
								v-show="authorization('/home-admin/routineManage/material-type')"
								index="/home-admin/routineManage/material-type"
								>物料类型</el-menu-item
							>
							<el-menu-item
								v-show="authorization('/home-admin/routineManage/material-model')"
								index="/home-admin/routineManage/material-model"
								>物料型号</el-menu-item
							>
							<el-menu-item
								v-show="authorization('/home-admin/routineManage/cable-brand')"
								index="/home-admin/routineManage/cable-brand"
								>电缆品牌</el-menu-item
							>
							<el-menu-item
								v-show="authorization('/home-admin/routineManage/device-brand')"
								index="/home-admin/routineManage/device-brand"
								>充电桩品牌</el-menu-item
							>
							<el-menu-item
								v-show="authorization('/home-admin/routineManage/quickWord-manage')"
								index="/home-admin/routineManage/quickWord-manage"
								>快捷用语</el-menu-item
							>
						</el-submenu>
						<el-menu-item
							v-show="authorization('/home-admin/journal/login-journal')"
							index="/home-admin/journal/login-journal"
							title="登录日志"
						>
							<i class="el-icon-info" style="color: #fff"></i>
							<span>登录日志</span>
						</el-menu-item>
					</el-menu>
				</el-aside>
				<el-container>
					<el-header style="height: 6vh; background-color: #fff">
						<div class="change-icon" @click="setCollapse" style="float: left; margin-left: -20px">
							<i class="el-icon-s-fold"></i>
						</div>
						<el-breadcrumb separator="/" style="line-height: 40px; margin-left: 15px">
							<el-breadcrumb-item v-for="(item, index) in $route.meta" :key="index">
								<router-link v-if="item.url" :to="item.url">{{ item.name }}</router-link>
								<a v-else>{{ item.name }}</a>
							</el-breadcrumb-item>
							<div style="float: right; margin-right: 20px">
								<el-dropdown trigger="click" @command="handleCommand">
									<div class="el-dropdown-link">
										<span>角色：{{ roleName }}</span>
										<span style="margin-left: 12px">{{ userName }}</span>
										<i class="el-icon-arrow-down el-icon--right"></i>
									</div>
									<el-dropdown-menu slot="dropdown">
										<el-dropdown-item command="1">修改密码</el-dropdown-item>
										<el-dropdown-item command="2">退出</el-dropdown-item>
									</el-dropdown-menu>
								</el-dropdown>
							</div>
						</el-breadcrumb>
					</el-header>
					<el-main style="padding: 0; background-color: rgb(238, 238, 238)">
						<router-view
							style="overflow: auto; width: 100%; background-color: rgb(238, 238, 238)"
						/>
					</el-main>
				</el-container>
			</el-container>
		</el-row>
	</div>
</template>

<script>
	import { authorization } from '../../../authorization/authorization';
	export default {
		data() {
			return {
				isCollapse: false,
				menuWidth: '201px',
				contentWidth: 21,
				mainStyle: { height: '0px' },
				container: { height: '0px' },
				form: {
					oldPassword: '',
					newPassword: '',
					newPassword2: '',
				},
				dialogFormVisible: false,
				rules: {
					oldPassword: [{ required: true, message: '请输入旧密码', trigger: 'blur' }],
					newPassword: [{ required: true, message: '请输入新密码', trigger: 'blur' }],
					newPassword2: [{ required: true, message: '请再次新密码', trigger: 'blur' }],
				},
				userName: sessionStorage.getItem('userName'),
				roleName: '',
				baseInfo: '',
				commdityInfo: '',
				woOutInfo: '',
				warehouseInfoBo: '',
			};
		},
		created() {
			let roleList = JSON.parse(sessionStorage.getItem('roleList'));
			let resultName = '';
			for (let i = 0; i < roleList.length; i++) {
				if (i > 0) {
					resultName = resultName + ',';
				}
				resultName = resultName + roleList[i].roleName;
			}
			this.roleName = resultName;
		},
		mounted() {
			this.initHeight();
			window.onresize = () => {
				return (() => {
					this.initHeight();
				})();
			};
		},
		methods: {
			authorization,
			setCollapse() {
				if (this.isCollapse) {
					this.menuWidth = '201px';
					this.contentWidth = 21;
					this.isCollapse = false;
				} else {
					this.contentWidth = 23;
					this.menuWidth = '60px';
					this.isCollapse = true;
				}
			},
			initHeight() {
				//el-main高度自适应
				this.windowHeight =
					window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight; //浏览器高度
				this.mainStyle.height = this.windowHeight - 40 + 'px';
				this.container.height = this.windowHeight + 'px';
			},
			handleCommand(command) {
				if (command === '1') {
					this.form = {};
				}
				if (command === '2') {
					sessionStorage.removeItem('userName');
					this.$router.push('/');
				} else {
					this.dialogFormVisible = true;
				}
			},
			subMit(form) {
				if (this.form.newPassword2 !== this.form.newPassword) {
					this.$message({ type: 'error', message: '两次输入的密码不一致' });
					return;
				}
				this.$refs[form].validate((valid) => {
					if (valid) {
						this.axios({
							method: 'post',
							url: '/v1/h5/user-info/update-password',
							data: this.form,
						}).then((res) => {
							if (res.data.code === 200) {
								this.$message({
									type: 'success',
									message: '修改成功',
								});
								this.dialogFormVisible = false;
							} else {
								this.$message({
									type: 'error',
									message: res.data.message,
								});
							}
						});
					} else {
						return false;
					}
				});
			},
		},
	};
</script>

<style>
	.el-main {
		padding: 0;
		height: calc(100vh-60px);
	}

	.el-main > div {
		height: calc(100vh-60px);
	}

	.el-menu-item {
		margin-top: 10px;
	}

	.el-submenu {
		margin-top: 10px;
	}

	.el-dropdown-link {
		cursor: pointer;
	}

	.el-icon-arrow-down {
		font-size: 12px;
	}

	.change-icon {
		font-size: 30px;
	}

	.el-menu-vertical-demo:not(.el-menu--collapse) {
		width: 200px;
		min-height: 400px;
	}
</style>
